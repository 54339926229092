@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #121212;
  overscroll-behavior: none;
}

html {
  background-color: #121212;
}

